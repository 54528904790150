@import "animate.css";


.animation-parent {
    overflow: hidden;
}


.preset {
    &__fadeIn {
        opacity: 0;
    }

    &__slideInRight {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    &__fadeInDown {
        transform: translate3d(0, -100%, 0);
        opacity: 0;
    }

    &__fadeInLeft {
        transform: translate3d(-100%, 0, 0);
        opacity: 0;
    }

    &__fadeInRight {
        transform: translate3d(100%, 0, 0);
        opacity: 0;
    }

    &__fadeInUp {
        transform: translate3d(0, 100%, 0);
        opacity: 0;
    }


    &__flipInX {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    }

    &__flipInY {
        transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    }


    &__zoomIn {
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    }

    &__zoomOut {

    }

    &__slideInLeft {

    }

    &__slideInRight {

    }

}


$pos: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20;
@each $key in $pos {
    .animate__delay-pos#{$key} {
        animation-delay: calc(0.2s * #{$key});
    }
    .animate__delay-fast-pos#{$key} {
        animation-delay: calc(0.05s * #{$key});
    }
}
